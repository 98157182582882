import React from "react"
import Socials from "../components/Socials"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"
import Campaign from "../components/CampaignForm"

const MyStory = () => {

  return(
    <Layout>
      <SEO title="My Story" description="Learn how Steve Cook got started, what he's up to now, and what his plans are going into the future." />
      <div className="paper py-20 px-5">
        <div className="container mx-auto my-10">
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 justify-center">
              <h1 className="text-4xl text-center">My Story</h1>
              <div className="w-full py-10">
                <Video
                  videoSrcURL="https://www.youtube.com/embed/lI5muX7AshE"
                  videoTitle="Train for Life | Steve Cook"
                />
              </div>  

              <div>
              <p className="mt-2 font-light">Growing up in Boise, Idaho, I was the middle child of 7, in a very athletic family. With my dad as my mentor and trainer, I started hitting the gym and the track from an early age. I immediately fell in love with fitness, and there wasn’t much that I couldn’t do.</p>
              <p className="mt-2 font-light">By the time I started high school, I was smashing all the weight lifting records and playing high school football, which later led me onto a college football career. At 23 years old, my football career had just ended earlier than I wanted it to, I was fresh off a divorce, a college drop-out, and I knew I needed to make a change.</p>
              <p className="mt-2 font-light">I decided to dig deep and do the thing I knew best. Pick me up off the floor and get after it.</p>
              <p className="mt-2 font-light">I was approached to take part in the Boise Fit Expo’s Fit Body Contest. After coming away with a win, I soon started to look for other competitions. I found the Muscle & Fitness Male model search competition in Las Vegas held during the Olympia. I worked hard and got into the best shape of my life, all while finishing my bachelor’s degree. At the conclusion of the contest, I walked away from the winner. That win really springboarded me into the fitness scene.</p>
              <p className="mt-2 font-light">Since then, I’ve competed in multiple bodybuilding and physique competitions, and have been a spokesperson for bodybuilding.com, Optimum Nutrition, and Gymshark. I’ve appeared on multiple magazine covers including Muscle & Fitness, Men's Fitness and Iron Man. And most recently I became the newest coach on USA Network’s “The Biggest Loser.”</p>
              <p className="mt-2 font-light">In addition, I’ve begun my journey of creating and producing my own fitness content. I’ve shared education and inspiration on YouTube and Instagram, becoming one of the most recognizable faces in fitness.</p>
              <p className="mt-2 font-light">Currently, I own a gym in St. George, UT, and a fitness app called Fitness Culture. I spend most of my time traveling, working out, chilling with my French Bulldog, Hobbes, and loving what I do.</p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 flex justify-center">
              <Campaign campaign="492448630" cta="Become a VIP" />
            </div>
          </div>
          <div className="mb-10">
            <Socials />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MyStory